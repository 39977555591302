import axios from 'axios';
import axiosRetry from 'axios-retry';
import server from './server.json';
import { getToken } from './user';

axiosRetry(axios, { retries: 3 });

const urlapi = process.env.VUE_APP_URL_API + '/api/catpros.json';

async function getCategorias(data: any){

    const gcategorias = await axios.get(urlapi, {
        params: {
            'access_token': data.token.access_token,
            }
     })
    .then(res => {
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        if (err.satus == 401){
            getToken().then(()=>{
                console.log('try get again category')
                gcategorias;
                window.location.reload();
            })
        }
    })

    return gcategorias;
}

export {
    getCategorias,  
}