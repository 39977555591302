async function getCep(cep: any){
    const rr = await fetch('https://opencep.com/v1/' + cep + '.json', {
            method: "GET"
        }).then((res)=>{
            // console.log('res',res.json())
            return res.json();
        });

    return rr
}

export {
    getCep
}