async function getCoordinate(address: any){

    const mapboxC = await fetch('https://api.mapbox.com/geocoding/v5/mapbox.places/' + address + '.json?access_token=pk.eyJ1IjoiYWxleGFuZHJlc2VlbmV4dHUiLCJhIjoiY2x0OGhydGxtMHhjeDJpbzgxbGlqcmphOCJ9.wIDreCem-kyNsjRkh9-TEg', 
    {
        method: 'GET',
    });
    return await mapboxC.json();
}

async function getDistance(pa: any,pb: any){
    const search = new URLSearchParams({
        radiuses: '200;200',
        access_token: 'pk.eyJ1IjoiYWxleGFuZHJlc2VlbmV4dHUiLCJhIjoiY2x0OGhydGxtMHhjeDJpbzgxbGlqcmphOCJ9.wIDreCem-kyNsjRkh9-TEg'
    });
    const distance = await fetch('https://api.mapbox.com/directions/v5/mapbox/driving/' + pb + ',' + pa + ';'+ process.env.VUE_APP_STORE_LNG + ',' + process.env.VUE_APP_STORE_LAT  + '?' + search, 
    {
        method: 'GET',
    });
    return await distance.json();
}

export {
    getCoordinate,
    getDistance
}