import axios from 'axios';
import axiosRetry from 'axios-retry';
// import server from './server.json'
// import dotenv from "dotenv";

// dotenv.config();

axiosRetry(axios, { retries: 2 });

async function getToken(){
    const postData = { 
        email: "alesb@uol.com.br",
        password: "ale102030",
        grant_type: "password"
    };      

    const gtoken = await axios.post(process.env.VUE_APP_URL_API + '/oauth/token',postData, {
    headers: {
        'Content-Type':  'application/json',
        'Authorization': 'access-token'
        }
    })
    .then(res => {
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    })

    return gtoken;
}

// interface Dt {
//     fone: string,
//     access_token: string
// }

async function getUserDataByFone(dataft: any){
    const dataParams = { 
        access_token: dataft.token,
        fone: dataft.fone
    };      

    const userdata = await axios.get(process.env.VUE_APP_URL_API + '/api/clientes/buscafone.json', {
        params: dataParams
    })
    .then(res => {
        return res.data;
    })
    .catch((err)=>{
        console.log(err);
        return {status: 'notfound'};
    })

    return userdata;
}

async function postClientUpdateData(datacli: any){
    // const indexuseraddress = datacli.user.length - 1;
    const dataforcli = {
        email: datacli[0].email,
        cpf: datacli[0].cpf,
    };
    const dataLogradouro = {
            // id: datacli.user[0].id,
            endereco: datacli[1].endereco,
            numero: datacli[1].numero,
            complemento: datacli[1].complemento,
            bairro: datacli[1].bairro,
            cidade: datacli[1].cidade,
            uf: datacli[1].uf,
            referencia: datacli[1].referencia,
            // cliente_id: datacli.user[0].cliente_id,
            cep: datacli[1].cep,
            longitude: datacli[1].longitude,
            latitude: datacli[1].latitude,
            distancia: datacli[1].distancia
    };
    // console.log('datacli', datacli);
    await axios.patch(process.env.VUE_APP_URL_API + '/api/clientes/' + datacli.user[0].id + '.json', {
        access_token: datacli.token.access_token,
        cliente: dataforcli
      },
      {
        headers:{
          'Content-Type':  'application/json'
        }
      })

      await axios.patch(process.env.VUE_APP_URL_API + '/api/updatelogradouro/' + datacli.user[1].id + '.json', {
        access_token: datacli.token.access_token,
        logradouro: dataLogradouro
      },
      {
        headers:{
          'Content-Type':  'application/json'
        }
      })
    
}

async function postClientNewInsertData(datacli: any){
    const indexuseraddress = datacli.user.length - 1;
    const dataforcli = {
        nome: datacli[0].nome,
        telefone1: datacli[0].telefone1.replace('(', '').replace(')', '').replace('-',''),
        email: datacli[0].email,
        cpf: datacli[0].cpf,
        logradouros_attributes: [{
            // id: datacli.user[indexuseraddress].id,
            endereco: datacli[1].endereco,
            numero: datacli[1].numero,
            complemento: datacli[1].complemento,
            bairro: datacli[1].bairro,
            cidade: datacli[1].cidade,
            uf: datacli[1].uf,
            referencia: datacli[1].referencia,
            // cliente_id: datacli.user[indexuseraddress].cliente_id,
            cep: datacli[1].cep,
            longitude: datacli[1].longitude,
            latitude: datacli[1].latitude,
            distancia: datacli[1].distancia
        }]
    }
    // console.log('datacli', datacli);
    const ccli = await axios.post(process.env.VUE_APP_URL_API + '/api/clientes.json', {
        access_token: datacli.token.access_token,
        cliente: dataforcli
      },
      {
        headers:{
          'Content-Type':  'application/json'
        }
      })
    
      return ccli;
}


// async function refreshToken(user, password, token){
//     await axios.post('',{

//     })
//     .then()
//     .catch((err)=>{
//         console.log(err)
//     })
// }

export {
    getToken,
    getUserDataByFone,
    postClientUpdateData, 
    postClientNewInsertData
}