import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
import CheckoutStart from '../views/CheckoutStart.vue';
import CheckoutCliente from '../views/CheckoutCliente.vue';
import CheckoutAlteraDadosCliente from '../views/CheckoutAlteraDadosCliente.vue';
import CheckoutFinal from '../views/CheckoutFinal.vue';
import CheckoutThankyou from '../views/CheckoutThankyou.vue';
import CheckoutClienteNovo from '../views/CheckoutClienteNovo.vue';
import HomeIntro from '../views/HomeIntro.vue';


const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/'
  // },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/menu'
      },
      {
        path: 'menu',
        component: () => import('@/views/PrincipalMenu.vue')
      },
      {
        path: 'carrinho',
        component: () => import('@/views/PrincipalCarrinho.vue')
      },
      {
        path: 'conta',
        component: () => import('@/views/PrincipalConta.vue')
      }
    ]
  },
  {
    path: '/checkout',
    component: CheckoutStart,
    name: 'checkout'
  },
  {
    path: '/checkoutcliente',
    component: CheckoutCliente,
    name: 'checkoutcliente'
  },
  {
    path: '/chechoutalteradados',
    component: CheckoutAlteraDadosCliente,
    name: 'chechoutalteradados'
  },
  {
    path: '/checkoutclientenovo',
    component: CheckoutClienteNovo,
    name: 'checkoutclientenovo'
  },
  {
    path: '/checkout-final',
    component: CheckoutFinal,
    name: 'checkoutfinal'
  },
  {
    path: '/thankyou',
    component: CheckoutThankyou,
    name: 'thankyou'
  },
  {
    path: '/',
    component: HomeIntro,
    name: 'home'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
